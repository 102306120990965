<template>
  <div :style="'background-image: url(' + backgroundImage + '); background-size: cover; background-repeat: no-repeat;'">
    <b-container fluid>
      <b-row align-v="center" align-h="center" style="height: 100vh">
        <b-col sm="10" md="8" align-v="center">
          <b-card align="center" align-v="center" id="at" style="background-color: rgba(54, 138, 155, 0.8);margin-top: 50px;border-radius: 20px;box-shadow: 5px 5px 10px #5abcae;">
            <div>
              <b-row>
                <b-col cols="2"> </b-col>
                <b-col cols="8">
                  <h4 style="color: white;">WisdomEra</h4>
                </b-col>
                <b-col cols="2"></b-col>
              </b-row>
            </div>
            <b-card style="margin-top: 0px;background-color: rgba(255, 255, 255, 0.7);border-radius: 15px;">
              <h5 style="color: black;">Kayıt Ekranı</h5>
              <b-row>
                <b-col cols="6" style="padding-left: 15px; padding-top: 0px; text-align: left">
                  Ad
                </b-col>
                <b-col cols="6" style="padding-left: 15px; padding-top: 0px; text-align: left">
                  Soyad
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" style="padding-left: 3px; padding-top: 0px;">
                  <b-form-input type="text" class="form-control-warning" id="first_name" key="first_name" v-model="d_newUserData.first_name" :state="d_newUserData.first_name.length > 0"></b-form-input>
                  <b-form-valid-feedback></b-form-valid-feedback>
                  <b-form-invalid-feedback>Lütfen adınızı giriniz</b-form-invalid-feedback>
                </b-col>
                <b-col cols="6" style="padding-left: 3px; padding-top: 0px;">
                  <b-form-input type="text" class="form-control-warning" id="last_name" key="last_name" v-model="d_newUserData.last_name" :state="d_newUserData.last_name.length > 0"></b-form-input>
                  <b-form-valid-feedback></b-form-valid-feedback>
                  <b-form-invalid-feedback>Lütfen soyadınızı giriniz</b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" style="padding-left: 15px; padding-top: 0px; text-align: left">
                  Kullanıcı Adı
                </b-col>
                <b-col cols="6" style="padding-left: 15px; padding-top: 0px; text-align: left">
                  Email
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" style="padding-left: 3px; padding-top: 0px;">
                  <b-form-input type="text" class="form-control-warning" id="username" key="username" :formatter="f_toLowerCase(d_newUserData.username, 'username')" v-model="d_newUserData.username" :state="f_usernameRegexCheck(d_newUserData.username)"></b-form-input>
                  <b-form-valid-feedback></b-form-valid-feedback>
                  <b-form-invalid-feedback>Kullanıcı adınız sadece ingilizce harfler, sayılar ve nokta işaretlerinden oluşabilir</b-form-invalid-feedback>
                </b-col>
                <b-col cols="6" style="padding-left: 3px; padding-top: 0px;">
                  <b-form-input type="text" class="form-control-warning" id="email" key="email" :formatter="f_toLowerCase(d_newUserData.email, 'email')" v-model="d_newUserData.email" :state="f_emailRegexCheck(d_newUserData.email)"></b-form-input>
                  <b-form-valid-feedback></b-form-valid-feedback>
                  <b-form-invalid-feedback>Lütfen düzgün bir email giriniz</b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" style="padding-left: 15px; padding-top: 0px; text-align: left">
                  Şifre
                  <span @click="f_generateRandomPassword()" style="padding-left: 15px; cursor: pointer; text-decoration: underline; font-style: italic;"> Random Şifre</span>
                </b-col>
                <b-col cols="6" style="padding-left: 15px; padding-top: 0px; text-align: left">
                  Şifre Tekrarı
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" style="padding-left: 3px; padding-top: 0px;">
                  <b-input-group label-for="system_name" label-cols="6" validated>
                    <b-form-input :type="d_showPasswordType" class="form-control-warning" id="password" key="password" v-model="d_newUserData.password" :state="f_checkPasswordFilled()"></b-form-input>
                    <b-input-group-append>
                      <b-input-group-text title="Şifreyi Göster-Gizle" style="cursor: pointer;" @click="f_showPasswordType()">
                        <i class="fa fa-eye"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                    <b-form-valid-feedback></b-form-valid-feedback>
                    <b-form-invalid-feedback>Şifreniz minimum 8 karakterli olmalıdır
                      <br />Şifre tekrarı alanını doldurmanız gerekmektedir</b-form-invalid-feedback>
                  </b-input-group>
                </b-col>
                <b-col cols="6" style="padding-left: 3px; padding-top: 0px;">
                  <b-form-input :type="d_showPasswordType" class="form-control-warning" id="d_passwordRepeat" key="d_passwordRepeat" v-model="d_passwordRepeat" :state="f_checkPasswordFilled()"></b-form-input>
                  <b-form-valid-feedback></b-form-valid-feedback>
                  <b-form-invalid-feedback>Şifreniz minimum 8 karakterli olmalıdır
                    <br />Şifre tekrarı alanını doldurmanız gerekmektedir</b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row>
                <template v-if="d_isAdminRegister">
                  <b-col cols="12">
                    <b-input-group prepend="Promocode Seçim Alanı" label-for="system_name" label-cols="6" validated>
                      <b-form-select id="d_newUserData.promocode" v-model="d_newUserData.promocode" :plain="true">
                        <option v-for="(promo, promo_ind) in d_promoCodeList" :value="promo.value">
                          {{ promo.label }}
                        </option>
                      </b-form-select>
                    </b-input-group>
                  </b-col>
                </template>
                <template v-else>
                  <b-col cols="4" style="padding-left: 3px; padding-top: 0px;">
                    <b-form-checkbox v-model="d_eula" :state="d_eula">EULA Onayı</b-form-checkbox>
                    <b-form-invalid-feedback :state="d_eula"><span @click="d_showEULA = true;" style="cursor: pointer; text-decoration: underline;">Metni Görüntüle</span></b-form-invalid-feedback>
                    <b-form-valid-feedback :state="d_eula"><span @click="d_showEULA = true;" style="cursor: pointer; text-decoration: underline;">Metni Görüntüle</span></b-form-valid-feedback>
                  </b-col>
                  <b-col cols="4" style="padding-left: 3px; padding-top: 0px;">
                    <b-form-checkbox v-model="d_kvkk" :state="d_kvkk">KVKK Onayı</b-form-checkbox>
                    <b-form-invalid-feedback :state="d_kvkk"><span @click="d_showKVKK = true;" style="cursor: pointer; text-decoration: underline;">Metni Görüntüle</span></b-form-invalid-feedback>
                    <b-form-valid-feedback :state="d_kvkk"><span @click="d_showKVKK = true;" style="cursor: pointer; text-decoration: underline;">Metni Görüntüle</span></b-form-valid-feedback>
                  </b-col>
                  <b-col cols="4" style="padding-left: 3px; padding-top: 0px;">
                    <b-form-input type="text" id="label" v-model="d_newUserData.promocode" :formatter="f_formatPromocodeRegex" placeholder="PROMOCODE ALANI"></b-form-input>
                  </b-col>
                </template>
              </b-row>
              <div slot="footer">
                <b-button size="sm" style="background-color: #368a9b;width: 100px;color: #c1e4e1;" @click="f_resetForm()"><i class="fa fa-ban" style="color: #f87574;"></i>&nbsp; temizle
                </b-button>
                <b-button :disabled="!f_checkFormFilled()" size="sm" style="background-color: rgb(54, 138, 155);width: 100px;color: #c1e4e1;" @click="f_startRegister()"><i class="fa fa-sign-in" style="color: #45dc45;"></i>&nbsp; kayıt ol
                </b-button>
              </div>
            </b-card>
            <span style="color:white; text-align: center; display: inline-block; margin-bottom: 10px; margin-top: 10px;">A New Era is Beginning by WisdomEra</span>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal v-if="d_showEULA" @close="d_showEULA = false" large="True">
      <h3 slot="header"> EULA Metni </h3>
      <div slot="body" v-html="d_agreements.eula">
        {{ d_agreements.eula }}
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showEULA = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showKVKK" @close="d_showKVKK = false" large="True">
      <h3 slot="header"> KVKK Aydınlatma Metni </h3>
      <div slot="body" v-html="d_agreements.kvkk">
        {{ d_agreements.kvkk }}
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showKVKK = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import auth from '@/auth';
import wdm16 from '@/wdm16';
import store from '@/store';
import { WisdomRegex } from '@/services/public/functions';
import { mapGetters } from 'vuex';
import UserService from '@/services/users';
import {
  default as Modal
} from '@/components/widgets/Modal';
export default {
  name: 'Register',
  computed: mapGetters({
    StoreLoading: 'loading'
  }),
  data () {
    return {
      d_agreements: require('@/options/agreements').agreements,
      wdm16: wdm16.wdm16,
      backgroundImage: require('../../static/img/LoginBackground.png'),
      store: store,
      d_passwordRepeat: '',
      d_newUserData: { 'email': '', 'first_name': '', 'last_name': '', 'password': '', 'username': '', 'promocode': '', 'is_admin_register': 0 },
      d_formCheckList: { 'email': false, 'password': false, 'username': false },
      d_eula: false,
      d_kvkk: false,
      d_showEULA: false,
      d_showKVKK: false,
      d_isAdminRegister: 0,
      d_promoCodeList: [],
      d_showPasswordType: "password"
    };
  },
  created: function () {
    if (this.$router.currentRoute.name === 'administrator_register') {
      this.d_isAdminRegister = 1;
      this.f_preparePromocodeList();
    }
    let wisdomera_system = JSON.parse(localStorage.getItem('wisdomera_system'));
    if (wisdomera_system.system_type === 'customer') {
      this.$router.push('/dashboard');
    }
  },
  mounted: function () {
    if (this.$router.currentRoute.query.promocode) {
      this.f_fillUriPromocode();
    }
  },
  methods: {
    f_showPasswordType: function () {
      if (this.d_showPasswordType === 'password') {
        this.d_showPasswordType = 'text';
      } else {
        this.d_showPasswordType = 'password';
      }
    },
    f_generateRandomPassword: function () {
      var length = 10,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.d_newUserData.password = retVal;
      this.d_passwordRepeat = retVal;
    },
    f_preparePromocodeList: function () {
      UserService.prepare_promocodes()
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_promoCodeList = resp.data.result;
          } else {
            alert(resp.data.message);
            return;
          }
        });
    },
    f_fillUriPromocode: function () {
      let tmp_promocode = this.f_formatPromocodeRegex(this.$router.currentRoute.query.promocode);
      this.d_newUserData.promocode = tmp_promocode;
    },
    f_formatPromocodeRegex (value, event) {
      return WisdomRegex.regex1(value).value;
    },
    f_resetForm: function () {
      this.d_newUserData = { 'email': '', 'first_name': '', 'last_name': '', 'password': '', 'username': '' };
      this.d_passwordRepeat = '';
      this.d_eula = false;
      this.d_kvkk = false;
    },
    f_checkPasswordFilled: function () {
      if (this.d_passwordRepeat === this.d_newUserData.password && this.d_passwordRepeat.length >= 8) {
        let status = WisdomRegex.password(this.d_newUserData.password).status;
        this.d_formCheckList.password = status;
        return status;
      }
      this.d_formCheckList.password = false;
      return false;
    },
    f_checkFormFilled: function () {
      if (this.d_formCheckList.email && this.d_formCheckList.password && this.d_formCheckList.username && this.d_newUserData.first_name.length > 0 && this.d_newUserData.last_name.length > 0 && (this.d_isAdminRegister || (!this.d_isAdminRegister && this.d_eula && this.d_kvkk))) {
        return true;
      }
      return false;
    },
    f_usernameRegexCheck: function (username) {
      if (['wisdom', 'admin', 'administrator', 'wisdomera', 'moderator', 'system', 'sys', 'system.admin', 'sys.admin'].indexOf(username) !== -1) {
        this.d_formCheckList.username = false;
        return false;
      }

      // var regex = new RegExp("#" + stringToGoIntoTheRegex + "#", "g");
      // at this point, the line above is the same as: var regex = /#abc#/g;
      const regex = /^[\.a-z0-9]*$/ig;
      if (username) {
        let regexUsername = regex.exec(username);
        // console.log('regexUsername::::::', regexUsername)
        if (regexUsername && (username === regexUsername[0])) {
          this.d_formCheckList.username = true;
          return true;
        }
      }
      this.d_formCheckList.username = false;
      return false;
    },
    f_emailRegexCheck: function (email) {
      let status = WisdomRegex.email(email).status;
      this.d_formCheckList.email = status;
      return status;
    },
    f_toLowerCase: function (value, param) {
      this.d_newUserData[param] = value.toLowerCase()
    },
    f_startRegister: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt işlemi başlıyor' } });
      if (!this.d_formCheckList) {
        alert('hooop');
        return;
      }
      UserService.register(this.d_newUserData)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            if (this.d_isAdminRegister) {
              alert('Kullanici kayit islemi tamamlandi');
              this.f_resetForm();
            } else {
              this.$router.push('/dashboard');
            }
          } else {
            alert(resp.data.message);
            return;
          }
        });
    }
  },
  watch: {},
  components: {
    Modal
  }
}

</script>

<style type="text/css">
canvas {
  background-color: #000;
}

</style>

